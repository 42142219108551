<template lang='pug' src='./VectorPalettePanel.pug'/>
<style lang='scss' src='./VectorPalettePanel.scss'/>

<script lang="ts">
import UiColorPicker from '@/components/ui/ColorPicker/UiColorPicker.vue';
import UiHistogram from '@/components/ui/Histogram/UiHistogram.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import PaletteGradientColorPicker
  from '@/components/ui/PaletteGradientColorPicker/PaletteGradientColorPicker.vue';
import UiSlider from '@/components/ui/Slider/UiSlider.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { ImageNodeType } from '@/constants/types/ImageNodeType';
import { MapLayerUnifiedVectorModel } from '@/models/map/Layers/MapLayerUnifiedVectorModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import SettingsPalettePanel
  from '@/modules/map/container/SidePanel/ImagesPanel/SettingsPalettePanel.vue';
import BorderValuesTab
  from '@/modules/map/container/SidePanel/ImagesPanel/VectorPalettePanel/BorderValuesTab.vue';
import UniqValuesTab
  from '@/modules/map/container/SidePanel/ImagesPanel/VectorPalettePanel/UniqValuesTab.vue';
import AssetsGradients from '@/services/assets/AssetsGradients';
import {
  computed, defineComponent, onMounted, PropType, reactive, ref,
} from 'vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';

export default defineComponent({
  name: 'VectorPalettePanel',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    node: {
      type: Object as PropType<ImageNodeType>,
      required: true,
    },
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UiIcon,
    UniqValuesTab,
    BorderValuesTab,
    PaletteGradientColorPicker,
    UiHistogram,
    UiSlider,
    UiColorPicker,
    SettingsPalettePanel,
    UiDrawer,
  },
  emits: ['close', 'save', 'save-name', 'delete-palette'],
  setup(props, { emit }) {
    const vector = reactive<UnifiedVectorModel>(props.node.model as UnifiedVectorModel);

    const activeGradient = ref('');

    const loading = ref(true);

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;

    const uniqueTableData = ref<{value: string | number; color: string; legend: string}[]>([]);

    const layer = computed<MapLayerUnifiedVectorModel | undefined>(() => mapModel.value?.getLayer(vector.uuid) as MapLayerUnifiedVectorModel);

    onMounted(async () => {
      if (AssetsGradients.data.length > 0) {
        activeGradient.value = AssetsGradients.data[0].id;
      }
      loading.value = false;
    });
    const minMaxValue = computed(() => {
      // refreshKey;
      const key = layer.value?.palette?.property;
      if (!key || !vector.numberKeys.includes(key)) {
        return { min: 0, max: 0, diff: 0 };
      }
      const value = {
        min: Math.min(...vector.uniqueValues[key] as number[]),
        max: Math.max(...vector.uniqueValues[key] as number[]),
        diff: 0,
      };
      value.diff = value.max - value.min;
      return value;
    });

    onMounted(async () => {
      if (!layer.value && mapModel.value) {
        await (vector as UnifiedVectorModel).render(mapModel.value, props.node.key, props.node.label, props.node.paletteId);
      }
    });

    const close = () => {
      emit('close');

      if (!props.node.active) {
        mapModel.value?.removeLayer(vector.uuid);
      }
    };

    const apply = () => {
      layer.value?.redraw();
    };

    const save = () => {
      layer.value?.redraw();
      layer.value?.palette?.save();
      close();
    };

    const onColorChange = (event: string, row: {value: string | number; color: string; legend: string}) => {
      if (event.endsWith('ff')) {
        row.color = event.slice(0, 7).toUpperCase();
      } else {
        row.color = event.toUpperCase();
      }
    };

    return {
      loading,
      vector,
      layer,
      uniqueTableData,
      minMaxValue,
      apply,
      save,
      close,
      onColorChange,
    };
  },
});
</script>
