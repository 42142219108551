import { FieldsCollection } from '@/collection/FieldsCollection';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import type { MapInputType } from '@/constants/types/map/MapInputType';
import { MapFieldPaintType } from '@/constants/types/MapFieldPaintType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import EventBus from '@/services/eventBus/EventBus';
import { FeatureCollection } from 'geojson';
import { AnyLayer, GeoJSONSource, MapMouseEvent } from 'mapbox-gl';

const layerFillColor = [
  'case',
  ['boolean', ['feature-state', 'rating-ndvi'], false], [
    'case',
    ['==', ['get', 'avg_ndvi'], 'no data'], 'rgba(255, 255, 255, 0)',
    ['==', ['get', 'avg_ndvi'], 0], 'rgb(4, 18, 60)',
    ['>=', ['get', 'avg_ndvi'], 0.7], '#044804',
    ['>=', ['get', 'avg_ndvi'], 0.6], 'rgba(4, 96, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.5], 'rgba(28, 114, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.45], 'rgba(60, 134, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.4], 'rgba(84, 150, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.35], 'rgba(100, 162, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.3], 'rgba(116, 170, 4, 1)',
    ['>=', ['get', 'avg_ndvi'], 0.2], 'rgba(148, 182, 20, 1)',
    ['<', ['get', 'avg_ndvi'], 0.2], 'rgba(205, 215, 17, 1)',

    'rgba(255, 255, 255, 0)',
  ],
  ['boolean', ['feature-state', 'rating-ndvi-contrast'], false], [
    'case',
    ['has', '__ndviContrast'], ['get', '__ndviContrast'],
    'rgba(255, 255, 255, 0)',
  ],
  ['boolean', ['feature-state', 'rating-ndvi-group'], false], [
    'case',
    ['==', ['get', 'avg_ndvi'], 'no data'], 'rgba(255, 255, 255, 0)',
    ['==', ['get', 'ndvi_group'], 3], '#15a203',
    ['==', ['get', 'ndvi_group'], 2], '#ffd900',
    ['==', ['get', 'ndvi_group'], 1], '#ff0000',

    'rgba(21,21,21,0.51)',
  ],
  ['boolean', ['feature-state', 'rating'], false], ['case',
    ['==', ['get', 'rating'], 'A'], '#15a203',
    ['==', ['get', 'rating'], 'B'], '#ffd900',
    ['==', ['get', 'rating'], 'C'], '#ff0000',
    '#FFFFFF',
  ],
  ['boolean', ['feature-state', 'crop'], false], [
    'case',
    ['has', '__cropColor'], ['get', '__cropColor'],
    'rgba(255, 255, 255, 0.8)',
  ],
  ['boolean', ['feature-state', 'crop-rotation'], false], [
    'case',
    ['has', '__cropRateColor'], ['get', '__cropRateColor'],
    'rgba(255, 255, 255, 0)',
  ],
  'rgba(255, 255, 255, 0)',
];

const layerDef = (layerId: string, sourceId: string): AnyLayer => ({
  id: layerId,
  type: 'fill',
  source: sourceId,
  layout: {},
  paint: {
    // @ts-ignore
    'fill-color': layerFillColor,
    'fill-opacity': 0.1,
  },
  metadata: {
    type: 'field',
  },
});

const contourLayerDef = (layerId: string, sourceId: string): AnyLayer => ({
  id: layerId,
  type: 'line',
  source: sourceId,
  paint: {
    'line-color': ['case',
      ['boolean', ['feature-state', 'block'], false], '#ad0000',
      ['boolean', ['feature-state', 'edit'], false], '#6a4c93',
      ['boolean', ['feature-state', 'selected'], false], '#2980b9',
      ['boolean', ['feature-state', 'active'], false], '#c36200',
      ['boolean', ['feature-state', 'hover'], false], '#00ad57',
      '#00D9D9',
    ],
    'line-width': ['case',
      ['boolean', ['feature-state', 'editOutline'], false], 0,
      ['boolean', ['feature-state', 'edit'], false], 4,
      ['boolean', ['feature-state', 'active'], false], 3,
      ['boolean', ['feature-state', 'selected'], false], 3,
      ['boolean', ['feature-state', 'hover'], false], 2,
      2,
    ],
  },
});

const labelLayerDef = (layerId: string, sourceId: string): AnyLayer => ({
  id: layerId,
  type: 'symbol',
  source: sourceId,
  layout: {
    'text-field': ['get', 'label'],
    'text-anchor': 'center',
    'text-size': [
      'interpolate', ['linear'], ['zoom'],
      8, 0,
      9, 6,
      10, 7,
      12, 12,
      17, 15,
    ],
    'text-justify': 'center',
    'text-font': [
      'literal',
      ['Inter Bold'],
    ],
  },
  paint: {
    'text-color': '#4a4a4a',
    'text-halo-width': 1,
    'text-halo-color': 'rgba(255, 255, 255, 0.95)',
    'text-halo-blur': 1,
  },
});

export class MapLayerFieldsModel extends MapLayerModel implements IMapLayerModel {
  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'fields', input.uuid);

    this._data = input as FieldsCollection;

    if (this._mapModel?.map) {
      this._mapModel.map
        .addSource(this.sourceId, {
          type: 'geojson',
          data: this.calculateFeatureCollection(),
          dynamic: false,
        })
        .addLayer(layerDef(this.layerId, this.sourceId))
        .moveLayer(this.layerId, MapAnchorEnum.FIELD_FILL)
        .addLayer(contourLayerDef(`${this.layerId}-contour`, this.sourceId))
        .moveLayer(`${this.layerId}-contour`, MapAnchorEnum.FIELD_CONTOUR)
        .addSource(`${this.sourceId}-label`, {
          type: 'geojson',
          data: this.calculateLabelSource(),
        })
        .addLayer(labelLayerDef(`${this.layerId}-label`, `${this.sourceId}-label`))
        .moveLayer(`${this.layerId}-label`, MapAnchorEnum.FIELD_LABEL);

      this.layerIds.push(this.layerId);
      this.layerIds.push(`${this.layerId}-contour`);
      this.layerIds.push(`${this.layerId}-label`);
      this.sourceIds.push(this.sourceId);
      this.sourceIds.push(`${this.sourceId}-label`);

      mapModel.events.onClick((evt, options) => {
        if (options?.layerType === 'field') {
          const _features = this._mapModel?.map?.queryRenderedFeatures(evt.point, {
            layers: [this.layerId],
          });
          if (_features && _features.length > 0) {
            FieldsEvents.emitClick(_features[0].id as number, this._mapModel?.container || '');
          }
        }
      });

      this._mapModel.map.on('mousemove', (evt: MapMouseEvent) => {
        const id = this._mapModel?.map?.queryRenderedFeatures(evt.point, { layers: [this.layerId] }).map((f) => Number(f.id))[0] || 0;
        EventBus.$emit(EventsEnum.HoverField, this._mapModel?.container, id);
      });
    }

    FieldsEvents.onFeatureUpdated(() => {
      this.redrawFields();
    });
    this._mapModel?.events.onFieldPaint(() => {
      this.handlerFieldPaint();
    });
    this._mapModel?.events.onFieldUpdate(() => {
      this.redrawFields();
    });
  }

  private _activeCropId = -1

  get activeCropId(): number {
    return this._activeCropId;
  }

  private _data: FieldsCollection;

  get data(): FieldsCollection {
    return this._data;
  }

  private _selectedPaint: MapFieldPaintType = 'none'

  get selectedPaint(): MapFieldPaintType {
    return this._selectedPaint;
  }

  set selectedPaint(value: MapFieldPaintType) {
    this._selectedPaint = value;
  }

  private _labelsShow = {
    name: true,
    name_rus: true,
    square: true,
  }

  get labelsShow(): { square: boolean; 'name_rus': boolean; name: boolean } {
    return this._labelsShow;
  }

  set labelsShow(value: { square: boolean; 'name_rus': boolean; name: boolean }) {
    this._labelsShow = value;
  }

  handlerFieldPaint() {
    if (this._mapModel?.map) {
      const flags = ['rating-ndvi', 'rating-ndvi-group', 'rating-ndvi-contrast', 'paint', 'rating', 'fill', 'crop', 'crop-rotation'];
      const defaultState = flags.reduce((acc, flag) => { acc[flag] = false; return acc; }, {} as Record<string, boolean>);

      const states = flags.reduce((acc, flag) => {
        acc[flag] = flags.reduce((inacc, inflag) => {
          inacc[inflag] = flag === inflag;
          return inacc;
        }, {} as Record<string, boolean>);

        return acc;
      }, { default: defaultState } as Record<string, Record<string, boolean>>);

      const state = states[this._selectedPaint] || states.default;

      this._data.collection.forEach((f) => {
        if (this._mapModel?.map?.getSource(this.sourceId)) {
            this._mapModel.map?.setFeatureState(
              { source: this.sourceId, id: f.id },
              state,
            );
        }
      });
    }
  }

  redrawFields(): void {
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource).setData(this.calculateFeatureCollection());
    (this._mapModel?.map?.getSource(`${this.sourceId}-label`) as GeoJSONSource)?.setData(this.calculateLabelSource());
  }

  calculateFeatureCollection = (): FeatureCollection => ({
    type: 'FeatureCollection',
    features: this.data.collection.map((field) => ({
      ...JSON.parse(JSON.stringify(field.feature)),
      properties: {
        ...field.feature.properties,
        sq: field.sq,
        name: field.name,
        nameRus: field.nameRus,
        uuid: field.dto.properties.uuid,
      },
    })),
  } as FeatureCollection);

  calculateLabelSource = () => {
    const acc: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    this.data.collection.forEach((f) => {
      const labelWords = [];

      if (this._labelsShow.name && f.name) {
        labelWords.push(f.name);
      }
      if (this._labelsShow.name_rus && f.nameRus) {
        labelWords.push(f.nameRus);
      }
      if (this._labelsShow.square && f.sq) {
        labelWords.push(`${f.sq} га`);
      }

      acc.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: f.bounds.getCenter().toArray(),
        },
        properties: {
          label: labelWords.join('\n'),
          minzoom: 11,
        },
      });
    });

    return acc;
  }
}
