import StructList from '@/modules/struct/StructList';
import { isNumber } from '@/utils/isNumber';
import { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';

export const structIdChecker = async (router: Router): Promise<void> => {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const storageStructId = window.localStorage.getItem('structId');
    if (to.name === 'app') {
      if (!storageStructId) {
        await StructList.fetchStructs();
        if (StructList.structs.value.length > 0) {
          const structId = StructList.structs.value[0].id;
          // await router.push({ name: 'struct', params: { structId } });
          next({ name: 'struct', params: { structId } });
        }
      } else {
        next({ name: 'struct', params: { structId: storageStructId } });
      }
    } else if (to.params?.structId && isNumber(to.params.structId.toString())) {
      if (storageStructId !== to.params.structId) {
        window.localStorage.setItem('structId', to.params.structId.toString());
      }
    } else if (from?.name === 'app') {
      window.localStorage.removeItem('structId');
    }
    next();
  });
};
