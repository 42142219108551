import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9fffd4fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "VectorPalettePanel-blocks"
}
const _hoisted_2 = { class: "VectorPalettePanel-block" }
const _hoisted_3 = { class: "VectorPalettePanel-block" }
const _hoisted_4 = { class: "VectorPalettePanel-block" }
const _hoisted_5 = { class: "VectorPalettePanel-classes" }
const _hoisted_6 = { class: "VectorPalettePanel-class header" }
const _hoisted_7 = { class: "VectorPalettePanel-class-colorBox" }
const _hoisted_8 = { class: "VectorPalettePanel-class-value" }
const _hoisted_9 = { class: "VectorPalettePanel-class-label" }
const _hoisted_10 = { class: "VectorPalettePanel-class" }
const _hoisted_11 = { class: "VectorPalettePanel-class-colorBox" }
const _hoisted_12 = {
  key: 0,
  class: "VectorPalettePanel-class-value"
}
const _hoisted_13 = {
  key: 1,
  class: "VectorPalettePanel-class-label"
}
const _hoisted_14 = { class: "VectorPalettePanel-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_UiHistogram = _resolveComponent("UiHistogram")!
  const _component_UiSlider = _resolveComponent("UiSlider")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_PaletteGradientColorPicker = _resolveComponent("PaletteGradientColorPicker")!

  return (_ctx.layer.palette && _ctx.paletteData?.classCount)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('select-class-count', { ns: 'mapbox' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input_number, {
                modelValue: _ctx.paletteData.classCount,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paletteData.classCount) = $event)),
                min: 3,
                "value-on-clear": 3,
                max: Math.min(20, (_ctx.layer.palette?.values as number[]).length)
              }, null, 8, ["modelValue", "max"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.layer.palette?.values)
            ? (_openBlock(), _createBlock(_component_UiHistogram, {
                key: 0,
                values: _ctx.values,
                height: 220,
                classes: _ctx.paletteData.values,
                accuracy: _ctx.layer.palette?.range.diff
              }, null, 8, ["values", "classes", "accuracy"]))
            : _createCommentVNode("", true),
          _createVNode(_component_UiSlider, {
            modelValue: _ctx.histogramSlider,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.histogramSlider) = $event)),
            min: _ctx.layer.palette?.range.min,
            max: _ctx.layer.palette?.range.max,
            precision: _ctx.precision
          }, null, 8, ["modelValue", "min", "max", "precision"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('color')), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('value')), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layer.palette?.borderData.values, (c, idx) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_color_picker, {
                    pureColor: c.color,
                    "onUpdate:pureColor": ($event: any) => ((c.color) = $event),
                    "blur-close": "",
                    "round-history": true,
                    format: 'hex8',
                    "picker-type": 'chrome',
                    "use-type": 'pure'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(c.color?.toLocaleUpperCase()), 1)
                    ]),
                    _: 2
                  }, 1032, ["pureColor", "onUpdate:pureColor"])
                ]),
                (c.range)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(c.range.from) + " - " + _toDisplayString(c.range.to), 1))
                  : _createCommentVNode("", true),
                (c.label)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_el_input, {
                        modelValue: c.label,
                        "onUpdate:modelValue": ($event: any) => ((c.label) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 256))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_PaletteGradientColorPicker, {
            palette: _ctx.layer.palette
          }, null, 8, ["palette"])
        ])
      ]))
    : _createCommentVNode("", true)
}