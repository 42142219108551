import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import { MonitoringFilesList } from '@/models/monitoring/MonitoringFilesList';
import { MonitoringIndexesList } from '@/models/monitoring/MonitoringIndexesList';
import { TaskMapIndexOverlapDto } from '@/services/api/dto/taskMap/TaskMapIndexOverlapDto';
import { computed, ref } from 'vue';

const _activeFile = ref<FieldNirModel>();

const _indexesFiles = ref<MonitoringFilesList>(new MonitoringFilesList()); // key - fieldId

const _indexesKinds = ref<Record<number, MonitoringIndexesList>>({}); // key - structId

const loading = ref(false);

const _showChart = ref<boolean>(false);

const _activeHRS = ref(false);

const overlap = ref<TaskMapIndexOverlapDto>();

const _selectedWorkTask = ref<FieldTaskMapWorkModel>();

const _products = ref<{name: string, rate: number, type: string, unit: string, show: boolean}[]>([]);

const _showNir = ref(false);

const _showContrast = ref(false);

const _showCloudMask = ref(false);

const _indexLayer = ref<MapLayerIndexModel | undefined>();

export const useMonitoring = () => {
  const showCloudMask = computed({
    get: () => _showCloudMask.value,
    set: (s) => {
      _showCloudMask.value = s;
    },
  });

  const activeFile = computed({
    get: () => _activeFile.value,
    set: (v) => {
      _activeFile.value = v;
    },
  });

  const indexLayer = computed({
    get: () => _indexLayer.value,
    set: (v: MapLayerIndexModel | undefined) => {
      _indexLayer.value = v;
    },
  });

  const indexesFiles = computed(() => _indexesFiles.value);

  const showNir = computed({
    get: () => _showNir.value,
    set: (v: boolean) => {
      _showNir.value = v;
    },
  });

  const showContrast = computed({
    get: () => _showContrast.value,
    set: (v: boolean) => {
      _showContrast.value = v;
    },
  });

  const indexesKinds = computed({
    get: (): MonitoringIndexesList => {
      const structId = Number(window.sessionStorage.getItem('structId')) || 0;
      return _indexesKinds.value[structId];
    },
    set: (v: MonitoringIndexesList) => {
      const structId = Number(window.sessionStorage.getItem('structId')) || 0;
      _indexesKinds.value[structId] = v;
    },
  });

  const showChart = computed({
    get: () => _showChart.value,
    set: (v) => {
      _showChart.value = v;
    },
  });
  const activeHRS = computed({
    get: () => _activeHRS.value,
    set: (v) => {
      _activeHRS.value = v;
    },
  });

  const products = computed(() => _products.value);

  const selectedWorkTask = computed({
    get: () => _selectedWorkTask.value,
    set: (v) => {
      _selectedWorkTask.value = v;
      setTimeout(() => {
        if ((_selectedWorkTask.value?.geojson?.features?.length || 0) > 0 && _selectedWorkTask.value?.geojson?.features[0].properties?.prod) {
          _products.value = [];
          _selectedWorkTask.value.geojson.features[0].properties.prod.forEach((p: {name: string, rate: number, type: string, unit: string}) => {
            _products.value.push({
              name: p.name,
              rate: p.rate,
              type: p.type,
              unit: p.unit,
              show: true,
            });
          });
        }
      }, 200);
    },
  });

  return {
    loading,
    activeFile,
    indexesFiles,
    indexesKinds,
    showNir,
    showContrast,
    showChart,
    activeHRS,
    overlap,
    products,
    selectedWorkTask,
    showCloudMask,
    indexLayer,
  };
};
