import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { GradientModel } from '@/models/assets/GradientModel';
import ApiService from '@/services/api/ApiService';
import { reactive } from 'vue';

class AssetsGradients {
  public data = reactive<GradientModel[]>([]);

  async fetch(force = false): Promise<void> {
    const unit = Number(window.localStorage.getItem('structId')) || 0;

    await fetcher(LoadingNamesEnum.ASSETS_GRADIENTS_LIST, unit, force, async () => {
      const { data } = await ApiService.assets.gradientsList();

      this.data = data.map((v) => new GradientModel(v));
    });
  }
}

export default new AssetsGradients();
