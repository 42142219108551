import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { ImageFileType } from '@/constants/types/ImageFileType';
import { fetcher } from '@/lib/tools/fetcher';
import { RasterModel } from '@/models/RasterModel';
import { UnifiedVectorModel } from '@/models/vector/UnifiedVectorModel';
import ApiService from '@/services/api/ApiService';
import {
  reactive, Ref, ref, UnwrapRef,
} from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';

class StructFilesList {
  get rasters(): Ref<UnwrapRef<RasterModel[]>> {
    return this._rasters;
  }

  get vectors(): Ref<UnwrapRef<UnifiedVectorModel[]>> {
    return this._vectors;
  }

  get groups(): Ref<UnwrapRef<ImageFileType[]>> {
    return this._groups;
  }

  private _vectors = ref<UnifiedVectorModel[]>([]);

  private _rasters = ref<RasterModel[]>([]);

  private _groups = ref<ImageFileType[]>([]);

  constructor() {
    EventBus.$on(EventsEnum.SocketMessage, async (message: SocketMessageType) => {
      if (message.event.startsWith('GIS_RASTER')) {
        this.fetchRasters(true);
      }

      if (message.event.startsWith('GIS_VECTOR')) {
        this.fetchVectors(true);
      }
    });
  }

  async fetchGroups(force = false) {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    return fetcher(LoadingNamesEnum.STRUCT_FILES_GROUPS, unit.toString(), force, async () => {
      const { data } = await ApiService.gis.getFilesGroups();
      this._groups.value = data;
    });
  }

  fetchVectors(force = false) {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    this._vectors.value = [];

    return fetcher(LoadingNamesEnum.STRUCT_FILES_VECTORS, unit.toString(), force, async () => {
      const { data } = await ApiService.gis.getUnifiedVectors();
      this._vectors.value = data.map((v) => new UnifiedVectorModel(v));
    });
  }

  fetchRasters(force = false) {
    const unit = Number(window.localStorage.getItem('structId')) || 0;
    this._rasters.value = [];
    return fetcher(LoadingNamesEnum.STRUCT_FILES_RASTERS, unit.toString(), force, async () => {
      const { data } = await ApiService.gis.getRasters();
      this._rasters.value = data.map((v) => new RasterModel(v));
    });
  }
}

export default new StructFilesList();
