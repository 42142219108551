<template lang="pug" src="./MapContextMenu.pug"/>
<style lang="scss" src="./MapContextMenu.scss"/>

<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapModel } from '@/models/map/MapModel';
import MapAreaInfo from '@/modules/map/container/MapAreaInfo/MapAreaInfo.vue';
import TableInfo from '@/modules/map/container/MapContextMenu/TableInfo/TableInfo.vue';
import MapRastersList from '@/modules/map/MapRastersList';
import POIAddCompactBlock from '@/modules/poi/ui/POIAddCompactBlock/POIAddCompactBlock.vue';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { ElNotification } from 'element-plus';
import { LngLat, MapMouseEvent, PointLike } from 'mapbox-gl';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';

export default defineComponent({
  name: 'MapContextMenu',
  components: {
    POIAddCompactBlock,
    TableInfo,
    MapAreaInfo,
  },
  setup() {
    const openPoi = ref(false);
    const openCompareTableModal = ref(false);
    const vectorData = ref(false);
    const areaInfo = ref(false);
    const hoverElement = ref('');

    const event = ref<MapMouseEvent|undefined>();
    const contextEvent = ref<MouseEvent|undefined>();
    const position = ref<LngLat>();
    const point = ref<PointLike>();
    const rasterValue = ref<{
      name: string,
      value: number | string,
    }[]>([]);
    const mapContainerName = ref();

    const mapModel = ref<MapModel | undefined>();

    const isVector = ref(false);
    const closeContextMenu = (evt: MouseEvent | WheelEvent) => {
      let isContextMenu = false;
      const checkIsContextMenu = (el: HTMLElement) => el.id === 'MapContextMenu';
      let el = evt.target as Node;
      while (el) {
        if (checkIsContextMenu(el as HTMLElement)) {
          isContextMenu = true;
        }
        el = el.parentNode as Node;
      }
      if (!isContextMenu) {
        event.value = undefined;

        mapModel.value?.events.emitContextMenuClose();
      }
    };

    const fetchRaster = async (e: MapMouseEvent) => {
      // @TODO - не выполнять, если координаты не входят ни в один активный растр (activeRasters.bound)
      const topRaster = ref<string[]>([]);
      rasterValue.value = [];
      if (!MapRastersList.rasters.value.some((r) => r.active)) {
        return;
      }
      MapRastersList.rasters.value.forEach((item) => item.active && topRaster.value.push(item.source));
      const { data } = await ApiService.gis.locatoinInfo({
        source: topRaster.value.join(','),
        x: e.lngLat.lng.toString(),
        y: e.lngLat.lat.toString(),
      });

      data.filter((r) => r.band_values[0].value && r.band_values.length === 1)
        .forEach((r) => rasterValue.value.push({
          name: MapRastersList.rasters.value.find((s) => s.source === r.source_guid)?.alias || 'err',
          value: r.band_values[0].value,
        }));
    };

    window.document.addEventListener('mousedown', closeContextMenu);
    window.document.addEventListener('wheel', closeContextMenu);

    const clickContext = (mapContainer: MapContainerEnum, e: MapMouseEvent) => {
      mapModel.value = useMapContainers(mapContainer).mapModel.value;
      e.originalEvent.stopImmediatePropagation();
      e.preventDefault();
      event.value = e;
      position.value = new LngLat(event.value.lngLat.lng, event.value.lngLat.lat);
      hoverElement.value = 'context';
      mapContainerName.value = mapContainer;
      fetchRaster(e);
    };

    const computedStyle = computed(() => ({
      top: `${Number(contextEvent.value?.clientY)}px`,
      left: `${Number(contextEvent.value?.clientX)}px`,
    }));

    const openCreatePOI = () => {
      if (event.value && event.value?.lngLat) {
        position.value = new LngLat(event.value.lngLat.lng, event.value.lngLat.lat);
        openPoi.value = true;
        hoverElement.value = '';
      }
    };
    const openAreaInfo = () => {
      areaInfo.value = true;
      mapModel.value?.drawerLayer?.stopDraw();
    };

    const openVectorData = () => {
      if (event.value && event.value?.lngLat) {
        position.value = new LngLat(event.value.lngLat.lng, event.value.lngLat.lat);
        vectorData.value = true;
        useMapLayout().showBlock('VectorDataBlock');
        event.value = undefined;
        hoverElement.value = '';
      }
    };
    const openTable = () => {
      if (event.value && event.value?.lngLat && event.value?.point) {
        position.value = new LngLat(event.value.lngLat.lng, event.value.lngLat.lat);
        point.value = event.value?.point;
        openCompareTableModal.value = true;
        event.value = undefined;
        mapModel.value?.events.emitContextMenuClose();
      }
    };
    const copyCords = () => {
      position.value.lat;
      navigator.clipboard.writeText(`${position.value.lat.toFixed(8)} ${position.value.lng.toFixed(8)}`);
      ElNotification.success(`Следующие координаты были скопированны в буффер обмена: \n${position.value.lat.toFixed(8)} ${position.value.lng.toFixed(8)}`);
    };

    const checkLayerAreaInfo = computed(() => (mapModel.value?.getLayers([MapLayerTypeEnum.TASK_MAP_FACT]).length || 0) > 0);

    onMounted(() => {
      document.body.addEventListener('mousedown', (e: MouseEvent) => {
        contextEvent.value = e;
      });
      EventBus.$on(EventsEnum.ContextMenu, (a: MapContainerEnum, b: MapMouseEvent) => clickContext(a, b));
    });

    return {
      event,
      computedStyle,
      openCreatePOI,
      openPoi,
      position,
      vectorData,
      openVectorData,
      isVector,
      rasterValue,
      hoverElement,
      openCompareTableModal,
      openTable,
      openAreaInfo,
      point,
      contextEvent,
      areaInfo,
      mapContainerName,
      checkLayerAreaInfo,
      copyCords,
    };
  },
});
</script>
