<template lang="pug" src="./TaskMapIndexContent.pug"/>
<style lang="scss" src="./TaskMapIndexContent.scss"/>

<script lang="ts">
import Content from '@/components/shared/Content/Content.vue';
import MonitoringCompactBlock
  from '@/components/shared/MonotoringCompactBlock/MonotoringCompactBlock.vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiChart from '@/components/ui/Chart/UiChart.vue';
import UiHistogram from '@/components/ui/Histogram/UiHistogram.vue';
import UiSlider from '@/components/ui/Slider/UiSlider.vue';

import { useMapContainers } from '@/composables/useMapContainers';
import { useUser } from '@/composables/useUser';
import { techType } from '@/constants/constants/techTupe';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import Coordinates from '@/constants/types/mapbox/Coordinates';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import tiffPacker from '@/lib/tiff/tiffPacker';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import PermissionsList from '@/modules/permissions/PermissionsList';
import StructList from '@/modules/struct/StructList';
import CanvasNDVIImgService from '@/modules/taskMap/CanvasNDVIImgService';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import SelectNirAndIndex from '@/pages/task-map/create/desiccation-map/SelectNirAndIndex.vue';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import TiffReader from '@/utils/TiffReader';
import { ElNotification, FormInstance, FormRules } from 'element-plus';
import {
  computed, defineComponent, onMounted, ref, shallowRef, watch,
} from 'vue';
import { useUnload } from '@/composables/useUnload';

export default defineComponent({
  name: 'TaskMapIndexContent',
  components: {
    Content,
    RightPanel,
    UiChart,
    UiSlider,
    UiHistogram,
    MonitoringCompactBlock,
    SelectNirAndIndex,

  },
  setup() {
    const { user } = useUser();
    const {
      activeNirFile,
      activeIndex,
    } = useTaskMap();
    const {
      activeField,
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const ImgNDVI = ref<CanvasNDVIImgService>();
    const loading = ref(false);
    const componentRef = ref();
    const isFileLoaded = ref(false);
    const ruleFormRef = shallowRef<FormInstance>();
    let timer: ReturnType<typeof setTimeout>;
    const zoneLength = ref(3);
    const maxChartValue = ref(0);
    const minChartValue = ref(0);
    const isShowTaskImage = ref(true);

    const slider = ref<number[]>([12, 20, 1000]);
    const canvasModel = ref<MapCanvasModel>();
    const canvasLayer = ref<MapLayerCanvasModel>();
    const monitoringBlock = ref<InstanceType<typeof MonitoringCompactBlock>>();
    const uniqueKey = computed(() => Date.now());

    const histogramSlider = computed({
      get: () => slider.value,
      set: (_stops: number[]) => {
        _stops.forEach((v, idx) => {
          slider.value[idx] = Math.max(Math.min(maxChartValue.value * 100 - 1, Math.round(v)), minChartValue.value * 100 + 1);
        });
      },
    });

    function getValueBetweenMinMax(percentage: number): number {
      const range = (maxChartValue.value * 100) - (minChartValue.value * 100);
      const value = (minChartValue.value * 100) + (range * percentage) / 100;

      return value;
    }

    const selectDate = (nir: FieldNirModel | undefined) => {
      if (activeNirFile.value !== nir) {
        activeNirFile.value = nir;
      }
    };

    watch(TiffReader.data, (a) => {
      // @ts-ignore
      const arr: number[] = [...a[0].filter((n) => !Number.isNaN(n)) as number[]];

      maxChartValue.value = Math.max(...arr);

      minChartValue.value = Math.min(...arr);

      histogramSlider.value = zoneLength.value === 3 ? [getValueBetweenMinMax(30), getValueBetweenMinMax(70), maxChartValue.value * 100] : [getValueBetweenMinMax(30), getValueBetweenMinMax(50), getValueBetweenMinMax(70), maxChartValue.value * 100];
    });

    const zoneCount = ref([
      0,
      0,
      0,
      0,
    ]);
    const zoneArea = ref([0, 0, 0, 0]);
    const type = ref(0);

    const timeout = ref<ReturnType<typeof setTimeout>>();
    const zonesProc = ref([0, 50, 100]);

    const task = ref({
      task_name: '',
      field: activeField.value?.id || 0,
      index: 0,
      material: { norma: 100, name: '', unit: 'л' },
      user: { email: JSON.parse(JSON.stringify(user.value?.email)) || '' },
    });

    watch(zoneLength, (a) => {
      histogramSlider.value.splice(0, histogramSlider.value.length);
      if (a === 3) {
        const value = [getValueBetweenMinMax(30), getValueBetweenMinMax(70), maxChartValue.value * 100];
        histogramSlider.value = value;

        zonesProc.value = [0, 50, 100];
      }
      if (a === 4) {
        const value = [getValueBetweenMinMax(30), getValueBetweenMinMax(50), getValueBetweenMinMax(70), maxChartValue.value * 100];
        histogramSlider.value = value;
        zonesProc.value = [0, 50, 75, 100];
      }
    });

    const fetchZoneArr = async (dataArr: number[]) => {
      const index = activeField.value?.monitoringIndexes.find((v: FieldIndexMonitoringModel) => v.sceneId === activeNirFile.value?.scene.id);
      if (TiffReader.data.value && index?.geomCoordinates) {
        const { data } = await ApiService.gis.postCalculateHistogramArea({
          field: activeField.value?.id || 0,
          // @ts-ignore
          zones: zoneLength.value === 3 ? [1, 2, 3] : [1, 2, 3, 4],
          width: TiffReader.data.value?.width || 0,
          height: TiffReader.data.value?.height || 0,
          bbox: [...index.geomCoordinates[3], ...index.geomCoordinates[1]] || [0, 0, 0, 0],
          payload: tiffPacker(dataArr),
        }, StructList.activeStruct.value?.id || 0);

        zoneArea.value = data.areas.filter((f: number, i: number) => i !== 0);
      }
    };

    const read = async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.FIELD_INDEXES, activeField.value?.id);
      setTimeout(async () => {
        task.value.field = activeField.value?.id || 0;
        const index = activeField.value?.monitoringIndexes.find((v: FieldIndexMonitoringModel) => v.sceneId === activeNirFile.value?.scene.id && v.kind === activeIndex.value);
        if (canvasLayer.value !== undefined) {
          mapModel.value?.removeLayer(MapLayerTypeEnum.CANVAS);
          canvasLayer.value = undefined;
        }
        if (index?.tiffFile && canvasModel.value !== undefined) {
          await TiffReader.readFile(index.tiffFile);
          canvasModel.value.bbox = index.geomCoordinates as Coordinates;
          canvasLayer.value = mapModel.value?.render(canvasModel.value) as MapLayerCanvasModel;
        }
        task.value.index = index?.id || 0;
        (ImgNDVI.value = new CanvasNDVIImgService(canvasModel.value as MapCanvasModel));
        ImgNDVI.value?.PaintMatrixNDVI([...histogramSlider.value].slice(0, -1));
        ImgNDVI.value?.setShowCanvas(isShowTaskImage.value);
        await fetchZoneArr(ImgNDVI.value?.payload || []);
        loading.value = true;
        await (zoneCount.value = ImgNDVI.value?.zoneCount);
        canvasLayer.value?.updateImage();
      });
    };

    const paintCanvas = () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (ImgNDVI.value) {
          await ImgNDVI.value.PaintMatrixNDVI([...histogramSlider.value].slice(0, -1));
          await (zoneCount.value = ImgNDVI.value?.zoneCount);
        }
        canvasLayer.value?.updateImage();
      }, 50);
    };

    const redrawCanvas = async () => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }
      loading.value = true;
      timeout.value = setTimeout(async () => {
        await read();
        if (isShowTaskImage.value && ImgNDVI.value) {
          // @ts-ignore
          ImgNDVI.value?.setShowCanvas(true);
          ImgNDVI.value?.PaintMatrixNDVI([...histogramSlider.value].slice(0, -1));
          canvasLayer.value?.updateImage();
          await fetchZoneArr(ImgNDVI.value?.payload);
        }
        loading.value = false;
        isFileLoaded.value = true;
      }, 100);
    };

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      const canvas = document.createElement('canvas');
      canvas.id = 'imageTask';
      canvas.style.display = 'none';
      document.body.querySelector('.MapContainer-main')?.appendChild(canvas);
      canvasModel.value = new MapCanvasModel('imageTask', [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ]);
      redrawCanvas();
      if (activeField.value !== undefined) {
        mapModel.value?.removeLayer(MapLayerTypeEnum.CANVAS);
        ImgNDVI.value = undefined;
      }
    });
    watch(activeIndex, () => {
      redrawCanvas();
    });
    watch(activeNirFile, () => {
      redrawCanvas();
    });

    const rules = ref<FormRules>({
      task_name: [{
        required: true,
        message: 'Не заполнено поле',
        trigger: ['blur', 'change'],
      }, {
        min: 1,
        max: 250,
        message: 'Длина поля не должна превышать  250 символов',
        trigger: ['blur', 'change'],
      }],

    });

    const save = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      // eslint-disable-next-line consistent-return
      formEl.validate((valid) => {
        if (valid) {
          const index = activeField.value?.monitoringIndexes.find((v: FieldIndexMonitoringModel) => v.sceneId === activeNirFile.value?.scene.id);
          if (index && index.geomCoordinates && TiffReader.data.value?.height && TiffReader.data.value?.width) {
            const arr = zonesProc.value.map((z, i) => ({
              value: i + 1,
              zone: zonesProc.value.length === 3 ? ['1.1', '2.2', '3.3'][i] : ['1.1', '1.3', '2.2', '3.3'][i],
              proc: z,
              proc_seed: z,
            }));
            ApiService.taskMap.createTaskFromPayload({
              type: type.value,
              width: TiffReader.data.value?.width || 0,
              height: TiffReader.data.value?.height || 0,
              bbox: [...index.geomCoordinates[3], ...index.geomCoordinates[1]] || [0, 0, 0, 0],
              payload: tiffPacker(ImgNDVI.value?.payload || []),
              zones: arr,
              ...task.value,
            }, StructList.activeStruct.value?.id || 0).then((resp) => {
              ElNotification({
                title: 'Успешно',
                message: resp.data.description,
                type: 'success',
                position: 'bottom-right',
                customClass: 'tasksMap',
              });
            });
          }
        }
      });
    };

    const calculatedTotalArea = (count: number) => {
      let total = 0;
      zoneArea.value.forEach((z) => {
        total += z;
      });

      return ((count / total) * 100).toFixed(2) || 0;
    };
    const calculateTotalMaterial = computed(() => {
      let total = 0;
      zoneArea.value.forEach((z) => {
        total += z;
      });
      return total * task.value.material.norma || 0;
    });
    const calculateMaterialZone = computed(() => {
      let total = 0;
      zoneArea.value.forEach((z, index) => {
        if (zonesProc.value[index]) {
          total += Number((z * (zonesProc.value[index] / 100) * task.value.material.norma).toFixed(1));
        }
      });
      return total || 0;
    });

    const calculateSave = computed(() => {
      const totalMaterial = calculateTotalMaterial.value;
      const materialZone = calculateMaterialZone.value;
      let text;
      let typeSave;
      let procent;
      if (totalMaterial < materialZone) {
        text = (materialZone - totalMaterial).toFixed(1);
        typeSave = 1;
        procent = ((materialZone / totalMaterial) * 100 - 100);
      }
      if (totalMaterial > materialZone) {
        text = (totalMaterial - materialZone).toFixed(1);
        typeSave = 2;
        procent = ((materialZone / totalMaterial) * 100 - 100) * -1;
      }
      return { text, typeSave, procent: procent?.toFixed(1) };
    });

    const computedDataChart = computed(() => {
      if (TiffReader.data.value !== undefined) {
        const arr: number[] = [];
        // @ts-ignore
        // eslint-disable-next-line guard-for-in,no-undef,no-restricted-syntax
        for (const i of TiffReader.data.value[0]) {
          arr.push(Number(i));
        }
        return arr.map((a: number) => Number((a * 100).toFixed(0))).filter((a: number) => !Number.isNaN(a));
      }
      return [];
    });

    const computedClass = computed(() => slider.value.reduce((acc: { value: number, count: number, color: string, range: { from: number, to: number }}[], a: number, i: number) => {
      if (slider.value.length === 3) {
        if (i === 0) {
          acc.push({
            value: 0, count: 0, color: '#A50000', range: { from: minChartValue.value || 0, to: a },
          });
        } else if (i === 1) {
          acc.push({
            value: 0, count: 0, color: '#F0F700', range: { from: slider.value[0], to: a },
          });
          acc.push({
            value: 0, count: 0, color: '#60AF00', range: { from: a, to: maxChartValue.value * 100 || 0 },
          });
        }
      }
      if (slider.value.length === 4) {
        if (i === 0) {
          acc.push({
            value: 0, count: 0, color: '#A50000', range: { from: minChartValue.value || 0, to: a },
          });
        } else if (i === 1) {
          acc.push({
            value: 0, count: 0, color: '#E8A500', range: { from: slider.value[0], to: a },
          });
        } else if (i === 2) {
          acc.push({
            value: 0, count: 0, color: '#F0F700', range: { from: slider.value[1], to: a },
          });
          acc.push({
            value: 0, count: 0, color: '#60AF00', range: { from: a, to: maxChartValue.value * 100 || 0 },
          });
        }
      }
      return acc;
    }, []));

    watch(zoneCount, (f) => {
      if (f && [3, 4].some((a) => a === zoneLength.value)) {
        fetchZoneArr(ImgNDVI.value?.payload || []);
      }
    });

    watch(computedClass, () => {
      if (slider.value.length > 0) {
        paintCanvas();
      }
    });
    watch(zoneLength, () => {
      if ([3, 4].some((a) => a === zoneLength.value) && zoneLength.value !== null) {
        fetchZoneArr(ImgNDVI.value?.payload || []);
        paintCanvas();
      }
    });

    watch(activeField, (a, b) => {
      if (a?.id !== b?.id) {
        mapModel.value?.removeLayer(MapLayerTypeEnum.CANVAS);
        ImgNDVI.value = undefined;
      }
    });

    const selectTaskImg = () => {
      if (monitoringBlock.value?.selectedIndex) {
        monitoringBlock.value?.setSelectIndex(undefined);
      }
      isShowTaskImage.value = true;
      if (ImgNDVI.value) {
        ImgNDVI.value?.setShowCanvas(isShowTaskImage.value);

        ImgNDVI.value?.PaintMatrixNDVI([...histogramSlider.value].slice(0, -1));
        canvasLayer.value?.updateImage();
      }
    };

    const hideTaskImg = (index: 'monitoring' | 'task-view'| undefined | MonitoringIndexKindType) => {
      isShowTaskImage.value = index === 'task-view';

      if (ImgNDVI.value) {
        ImgNDVI.value?.setShowCanvas(isShowTaskImage.value);

        ImgNDVI.value?.PaintMatrixNDVI([...histogramSlider.value].slice(0, -1));
        canvasLayer.value?.updateImage();
      }
    };

    useUnload(() => {
      mapModel.value.removeLayer(MapLayerTypeEnum.MONITORING_INDEX);
      mapModel.value.removeLayer(canvasLayer.value.uuid);
      hideTaskImg(undefined);
    });

    return {
      PermissionsList,
      activeField,
      ruleFormRef,
      isFileLoaded,
      user,
      TiffReader,
      slider,
      task,
      save,
      rules,
      zoneLength,
      techType,
      type,
      zoneArea,
      calculateSave,
      calculateMaterialZone,
      calculateTotalMaterial,
      calculatedTotalArea,
      activeIndex,
      loading,
      zonesProc,
      maxChartValue,
      minChartValue,
      histogramSlider,
      computedDataChart,
      computedClass,
      mapModel,
      selectDate,
      MapContainerEnum,
      monitoringBlock,
      selectTaskImg,
      hideTaskImg,
      isShowTaskImage,
      uniqueKey,
      componentRef,
    };
  },
});
</script>
